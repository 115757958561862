import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import gallery from './modules/gallery';
import users from './modules/users';
import headerSearchData from './modules/headerSearch/actionCreator';
import ecommerce from './modules/ecommerce/product/actionCreator';
import orders from './modules/ecommerce/orders/actionCreator';
import profile from './modules/profile/actionCreator';
// import auth from './modules/authentication/axios/actionCreator';
import staffAuth from './modules/staffAuth/actionCreator';

export default createStore({
  modules: {
    themeLayout,
    gallery,
    users,
    headerSearchData,
    ecommerce,
    orders,
    profile,
    staffAuth
  },
});
