import mutations from './mutations';

const state = () => {
  return {
  }
};

const actions = {
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
