import axios from 'axios';
import { message } from "ant-design-vue";

const API_URL =  `${process.env.VUE_APP_API}/users/`;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'staff/login', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('token', JSON.stringify(response.data.token));
          message.success('Login Successful');
        }

        return response.data;
      }).catch(error => {
        message.error(error.response &&
          error.response.data &&
          error.response.data.message) 
          return Promise.reject(error);
      });
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();