import {DataService} from '../config/dataService/dataService';
import { message } from "ant-design-vue";

const API_URL = `${process.env.VUE_APP_API}/staff/`;

class ApiService {
  staffProfile() {
    return DataService
      .get(API_URL + "staff-profile")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        message.error(
          error.response && error.response.data && error.response.data.message
        );
        return Promise.reject(error);
      });
  }
 
}

export default new ApiService();
