export default {
  loginSuccess(state, user) {
    state.user = user.user;
    state.token = user.token;
  },
  loginFailure(state) {
    state.user = null;
    state.token = null;
  },
  authLoading(state, loading) {
    state.authLoading = loading;
  },
  logout(state) {
    state.user = null;
    state.token = null;
  },

};
