import Users from "./Users";
import Products from "./Products";
import Orders from "./Orders";
import Labels from "./Labels";
import Store from "./Store";
// import store from '@/vuex/store';

let routes = [
  {
    path: "",
    name: "dashboard",
    children: [
      // {
      //   path: "finance-dash",
      //   name: "finance-dash",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "demoThree" */ "@/view/dashboard/DemoThree.vue"
      //     ),
      // },
      // {
      //   path: "sales-dash",
      //   name: "sales-dash",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "demoSix" */ "@/view/dashboard/DemoSix.vue"
      //     ),
      // },
      {
        path: "/",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "demoEight" */ "@/view/dashboard/DemoEight.vue"
          ),
      },
      {
        path: "users-dash",
        name: "users-dash",
        component: () =>
          import(
            /* webpackChunkName: "demoEight" */ "@/view/dashboard/DemoEight.vue"
          ),
      },
      {
        path: "order-report",
        name: "order-report",
        component: () =>
          import(
            /* webpackChunkName: "orderReport" */ "@/view/dashboard/OrderReport.vue"
          ),
      },
      {
        path: "product-report",
        name: "product-report",
        component: () =>
          import(
            /* webpackChunkName: "orderReport" */ "@/view/dashboard/ProductReport.vue"
          ),
      },
    ],
  },

  ...Products,
  ...Orders,
  ...Labels,
  ...Store,
  ...Users,
];

export default routes;
