
const state = () => ({
  isLoading: false,
  error: null,
});

export default {
  namespaced: false,
  state,
};
