export default [
  {
    path: '/users',
    name: 'users',
    children: [
      {
        path: 'sellers',
        name: 'users',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/apps/ecommerce/Sellers.vue'),
      },
      {
        path: 'sellers/:id',
        name: 'singleUser',
        component: () => import(/* webpackChunkName: "detail" */ '../view/apps/ecommerce/seller/index.vue'),
      },
      {
        path: 'suppliers',
        name: 'suppliers',
        component: () => import(/* webpackChunkName: "suppliers" */ '../view/apps/ecommerce/suppliers.vue'),
      },
      {
        path: 'add-suppliers',
        name: 'Addsuppliers',
        component: () => import(/* webpackChunkName: "suppliers" */ '../view/apps/ecommerce/AddSuppliers.vue'),
      },
      {
        path: 'customers',
        name: 'customers',
        component: () => import(/* webpackChunkName: "suppliers" */ '../view/apps/ecommerce/customers.vue'),
      },
   
    ],
  },

];
