import AuthService from '../../../services/auth.service';
import mutations from './mutations';

const state = () => ({
  user: JSON.parse(localStorage.getItem('user')), 
  token: JSON.parse(localStorage.getItem('token')),
  authLoading: false,

});

const actions =  {
   
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  };

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
