export default [
  {
    path: "/products",
    name: "products",
    children: [
      {
        path: "list",
        name: "products",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "product" */ "../view/apps/ecommerce/product/Products.vue"
            ),
          grid: () =>
            import(
              /* webpackChunkName: "grid" */ "../view/apps/ecommerce/product/overview/Grid.vue"
            ),
        },
      },
      {
        path: "supplier/add-product/:id",
        name: "newProduct",
        component: () =>
          import(
            /* webpackChunkName: "detail" */ "../view/apps/ecommerce/product/AddProduct.vue"
          ),
      },
      {
        path: "details/:id",
        name: "singleProduct",
        component: () =>
          import(
            /* webpackChunkName: "detail" */ "../view/apps/ecommerce/product/EditProduct.vue"
          ),
      },
      {
        path: "supplier/:id",
        name: "singleSupplier",
        component: () =>
          import(
            /* webpackChunkName: "detail" */ "../view/apps/ecommerce/SupplierDetails.vue"
          ),
      }
    ],
  },
];
