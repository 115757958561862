
const state = () => ({
  isLoading: false,
  error: null,
});

const mutations = {
  filterGalleryBegin(state) {
    state.isLoading = true;
  },
  filterGallerySuccess(state, data) {
    state.isLoading = false;
    state.data = data;
  },
  filterGalleryErr(state, err) {
    state.isLoading = false;
    state.error = err;
  },
};

const actions = {
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
