export default [
  {
    path: '/labels',
    name: 'labels',
    children: [
      {
        path: 'sliders',
        name: 'sliders',
        component: () => import(/* webpackChunkName: "sliders" */ '../view/labels/sliders/index.vue'),
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "allParentCategories" */ '../view/labels/categories/index.vue'),
      },
      {
        path: 'categories/parent/:id',
        name: 'parentcategories',
        component: () => import(/* webpackChunkName: "parent" */ '../view/labels/categories/parent.vue'),
      },
      {
        path: 'categories/category/:id',
        name: 'singleCategory',
        component: () => import(/* webpackChunkName: "category" */ '../view/labels/categories/category.vue'),
      },
    ],
  },

];
