import mutations from './mutations';

const state = () => ({
  isProductLoading: false,
  isLoading: false,
  error: null,
});

const actions = {
  async filterSinglePage({ commit }, { paramsId, currentState }) {
    try {
      commit('singleProductBegin');
      const data = currentState.filter((product) => {
        return product.id === paramsId;
      });
      commit('singleProductSuccess', data);
    } catch (err) {
      commit('singleProductErr', err);
    }
  },


};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
