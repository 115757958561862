import mutations from './mutations';

const state = () => ({
  isLoading: false,
  error: null,
  postLoading: false,
});

const actions = {
 
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
