export default [
  {
    path: '/orders',
    name: 'orders',
    children: [
      {
        path: 'list',
        name: 'orders',
        component: () => import(/* webpackChunkName: "orders" */ '../view/apps/ecommerce/Orders.vue'),
      },
      {
        path: 'details/:id',
        name: 'singleOrder',
        component: () => import(/* webpackChunkName: "orderdetails" */ '../view/apps/ecommerce/order/index.vue'),
      },
    ],
  },

];
